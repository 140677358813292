import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Main } from '../parts/main';
import { WorkListContext, IWorkListContext } from './work-list.context';
import { Widgets } from '../widgets';
import { WorkListQueryQuery } from '../../../types/gatsby-types';
import { useLayoutTheme } from '../layout/layout';
import { PageSlice } from '../../../gatsby-node';
import { getThemeOrDefault } from '../../helpers/theme-helpers';

export interface IWorkListProps {
  limit: number;
  skip: number;
  numPages: number;
  currentPage: number;
}

export interface IWorkListTemplateProps {
  data: WorkListQueryQuery;
  pageContext: PageSlice & IWorkListProps;
}

const WorkListTemplate: React.FC<IWorkListTemplateProps> = ({
  data,
  pageContext,
}) => {
  const page = data.nmbl.page && data.nmbl.page[0];
  if (!page) {
    throw new Error('Error querying Work List Page.');
  }
  const workContext = React.useMemo<IWorkListContext>(
    () => ({
      ...data,
      ...pageContext,
      page,
    }),
    [data, pageContext, page]
  );
  useLayoutTheme(
    getThemeOrDefault(workContext.page.headerTheme?.theme, 'dark'),
    workContext.page.footerTheme?.theme ?? 'dark'
  );
  return (
    <Main template="work-list">
      <Helmet>
        <title>
          {workContext.page.seo?.title ?? workContext.page.displayText}
        </title>
      </Helmet>
      <WorkListContext.Provider value={workContext}>
        <Widgets widgets={workContext.page.bag?.contentItems} />
      </WorkListContext.Provider>
    </Main>
  );
};

export default WorkListTemplate;

export const pageQuery = graphql`
  query WorkListQuery($slug: String!, $skip: Int!, $limit: Int!) {
    nmbl {
      page(where: { alias: { alias: $slug } }) {
        ...PageFragment
      }
      work(orderBy: { publishedUtc: DESC }, first: $limit, skip: $skip) {
        ...WorkCardFragment
      }
    }
  }
`;
